<template>
    <div>
        <el-dialog
            :title="details_data.name"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="95%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <fieldset class="fieldsetc">
                    <legend class="legendc">基础信息</legend>
                    <el-descriptions
                        class="margin-top"
                        :column="3"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        size="small"
                    >
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <span>所属企业</span>
                            </template>
                            <span>{{details_data.enterprise_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>考核状态</span>
                            </template>
                            <el-tag size="mini" type="success" v-if="details_data.state">已完成</el-tag>
                            <el-tag size="mini" type="info" v-else>未完成</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>所属部门</span>
                            </template>
                            <span>{{details_data.department_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>被考核人</span>
                            </template>
                            <span>{{details_data.staff_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>总得分</span>
                            </template>
                            <span>{{details_data.sum_score === null ?  '-' : details_data.sum_score}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </fieldset>
                <fieldset class="fieldsetc">
                    <legend class="legendc">考核详情</legend>
                    <div v-if="details_data.state">
                        <el-table
                            :data="details_data.content"
                            :span-method="objectSpanMethod"
                            highlight-current-row
                            stripe
                            border
                            size="small"
                            style="width: 99%;"
                        >
                            <el-table-column align="center" type="index"></el-table-column>
                            <el-table-column
                                prop="title"
                                label="考核分类"
                                min-width="100px"
                                align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                label="项数"
                                min-width="70px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row['rank']===1">第一项</el-tag>
                                    <el-tag v-if="scope.row['rank']===2">第二项</el-tag>
                                    <el-tag v-if="scope.row['rank']===3">第三项</el-tag>
                                    <el-tag type="danger" v-if="scope.row['rank']===4">第四项</el-tag>
                                    <el-tag type="danger" v-if="scope.row['rank']===5">第五项</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="content"
                                label="考核内容"
                                min-width="200px"
                                align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="score_value"
                                label="评价"
                                min-width="100px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row['score_value']===0" type="danger" effect="plain">仍需努力</el-tag>
                                    <el-tag v-else-if="scope.row['score_value']===1" type="success" effect="plain">值得赞扬</el-tag>
                                    <span v-else>-</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="score_note"
                                label="评语"
                                min-width="150px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span style="text-align: left;white-space: pre-line;">{{scope.row['score_note']}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                            size="small"
                            style="width: 99%;"
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>总分</span>
                                </template>
                                <span style="font-weight: bold;font-size: 18px;"><span style="color: #2828FF;">30</span><span>&nbsp;分</span></span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>得分</span>
                                </template>
                                <span style="font-weight: bold;font-size: 18px;">{{details_data.sum_score === null ?  '-' : details_data.sum_score}}&nbsp;分</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2">
                                <template slot="label">
                                    <span>评分说明</span>
                                </template>
                                <span v-html="explain"></span>
                            </el-descriptions-item> 
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-table
                            :data="details_data.content"
                            :span-method="objectSpanMethod"
                            highlight-current-row
                            stripe
                            border
                            size="small"
                            style="width: 99%;"
                        >
                            <el-table-column align="center" type="index"></el-table-column>
                            <el-table-column
                                prop="title"
                                label="考核分类"
                                min-width="100px"
                                align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                label="项数"
                                min-width="70px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row['rank']===1">第一项</el-tag>
                                    <el-tag v-if="scope.row['rank']===2">第二项</el-tag>
                                    <el-tag v-if="scope.row['rank']===3">第三项</el-tag>
                                    <el-tag type="danger" v-if="scope.row['rank']===4">第四项</el-tag>
                                    <el-tag type="danger" v-if="scope.row['rank']===5">第五项</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="content"
                                label="考核内容"
                                min-width="200px"
                                align="center"
                            >
                            </el-table-column>
                            <el-table-column
                                label="评价"
                                min-width="100px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-radio-group
                                        v-model="form[scope.row['id']].value" 
                                        size="small" 
                                        :fill="opinion_color(form[scope.row['id']].value)" 
                                    >
                                        <el-radio-button :label="1">值得赞扬</el-radio-button>
                                        <el-radio-button :label="0">仍需努力</el-radio-button>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="评语"
                                min-width="150px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-input type="textarea" :rows="1" v-model="form[scope.row['id']].note" style="font-size: 12px;"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                            size="small"
                            style="width: 99%;"
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>总分</span>
                                </template>
                                <span style="font-weight: bold;font-size: 18px;"><span style="color: #2828FF;">30</span><span>&nbsp;分</span></span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>得分</span>
                                </template>
                                <span style="font-weight: bold;font-size: 18px;">{{count_score}}&nbsp;分</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span="2">
                                <template slot="label">
                                    <span>评分说明</span>
                                </template>
                                <span v-html="explain"></span>
                            </el-descriptions-item> 
                        </el-descriptions>
                    </div>
                </fieldset>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="(this.details_data.state === 0) && this.$_has('个人绩效评审')"
                    type="success"
                    plain
                    @click="sunmitDialog()"
                    >提交</el-button
                >
                <el-button
                    plain
                    @click="closeDialog()"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { table_judges_appraise_request } from '@/network/kpi/TableJudges.js'

export default {
    name: 'TableJudgesEditComponent',
    data() {
        return {
            login_loading: false,
            mergeObj: {},
            mergeArr: ['title'],
            form: {}
        }
    },
    computed: {
        count_score() {
            let s = 0
            if(this.form !== {}) {
                for(let key in this.form) {
                    if(this.form.hasOwnProperty(key)) {
                        s += this.form[key]['value']
                    }
                }
            }

            return s
        },
        explain() {
            let html =''
            html += '<span>1、价值观考核分为 6 大类目，每类分 5 项，每项分数为1分，共30项，合计30分。</span><br />'
            html += '<span>2、每项有【值得赞扬】和【仍需努力】两种评价，分别对应1分和0分。</span><br />'
            html += '<span>3、每种类目第1-3项如评价为【仍需努力】，则必须填写评语说明原由。</span><br />'
            html += '<span>4、每种类目第4-5项如评价为【值得表扬】，则必须填写评语说明原由。</span><br />'
            html += '<span style="color: #2828FF;">5、此份考核表所得分数不代表被考核人最终得分，但也具有重要影响，请考核人务必认真评价。</span><br />'
            html += '<span style="color: #FF0000;">6、此份考核表为匿名评价打分，考评人的真实姓名不会出现在该考评表及被考核人表里面。</span>'
            return html
        }
    },
    methods: {
        closeDialog() {
            this.$emit('exitDialog')
        },
        getSpanArr(data) {
            this.mergeArr.forEach((key, index1) => {
                let count = 0; // 用来记录需要合并行的起始位置
                this.mergeObj[key] = []; // 记录每一列的合并信息
                data.forEach((item, index) => {
                    // index == 0表示数据为第一行，直接 push 一个 1
                    if(index === 0) {
                        this.mergeObj[key].push(1); 
                    } else {
                        // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
                        if(item[key] === data[index - 1][key]) { 
                            this.mergeObj[key][count] += 1;
                            this.mergeObj[key].push(0);
                        } else {
                            // 如果当前行和上一行其值不相等 
                            count = index; // 记录当前位置 
                            this.mergeObj[key].push(1); // 重新push 一个 1
                        }
                    }
                })
            })
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            this.getSpanArr(this.details_data.content)
            // 判断列的属性
            if(this.mergeArr.indexOf(column.property) !== -1) { 
                // 判断其值是不是为0 
                if(this.mergeObj[column.property][rowIndex]) { 
                    return [this.mergeObj[column.property][rowIndex], 1]
                } else {
                    // 如果为0则为需要合并的行
                    return [0, 0]; 
                }
            }
        },
        opinion_color(v) {
            if(v === 1) {
                return '#00A600'
            }
            if(v === 0) {
                return '#CE0000'
            }
        },
        sunmitDialog() {
            this.$confirm(
                '是否确定提交（一旦提交，无法撤回和修改，请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    // this.login_loading = true
                    let stop = 0
                    Object.keys(this.form).forEach(k => {

                        if((this.form[k].rank < 4) && (this.form[k].value === 0) && (!this.form[k].note)) {
                            this.$message.error('前三项选择【仍需努力】时，必须填写评语')
                            stop = 1
                            return
                        }

                        if((this.form[k].rank >3) && (this.form[k].value === 1) && (!this.form[k].note)) {
                            this.$message.error('第四/第五项选择【值得赞扬】时，必须填写评语')
                            stop = 1
                            return
                        }
                    })

                    if(stop) {
                        return
                    }

                    table_judges_appraise_request({id: this.details_data.id,form: this.form})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('提交成功!')
                                this.$emit('exitDialog')
                                this.$emit('search')
                                this.$emit('details_row',this.details_data.id)
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        details_data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        details_data(newVal) {
            let f = {}
            newVal.content.forEach(e => {
                f[e.id] = { 
                    content_id: e.id,
                    rank: e.rank,
                    value: 1,
                    note: null
                }

                if(e.rank>3) {
                    f[e.id]['value'] = 0
                }
            })
            this.form = f
        }
    }
}
</script>

<style lang='less'></style>