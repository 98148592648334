<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="标题" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="标题搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    filterable
                    placeholder="选择状态"
                    class="statec"
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未评','已评']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'TableJudgesSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                state: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style></style>